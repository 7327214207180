import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const IndexPage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO
        title="Biometric Time & Attendance and Access Control"
        jsonLd={
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.beyondattendance.com",
            "name": "Beyond Attendance",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+27 (0)31 764 8550",
              "contactType": "Sales",
              "email": "sales@beyondattendance.com"
            },
            "sameAs": [
              "https://www.facebook.com/BeyondAttendance.co.za/"
            ]
          }
        }
      />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Does your business require"
        punchlines={["Time and Attendance?", "Multisite Control?", "Real Time Monitoring?", "Shift Control?", "Payroll Integration?", "Access Control?", "Portable Biometric Hardware?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition. Track your employees' attendance against configurable work rules and integrate directly into your payroll provider."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Time & Attendance and Access Control">
        <p>
          Beyond Attendance is perfectly suited to serve the needs of all industries from micro to enterprise sized organisations. With our user-friendly interface and highly affordable pricing models, we provide cloud-based Time and Attendance as well as Access Control so there are no expensive on-site servers required to host the platform. Your account can be created online within seconds.
        </p>
        <p>
          Beyond Attendance allows for integration with most payroll systems but can also be utilised as a substitute payroll application in conjunction with Microsoft Excel. Our mobile app is perfectly suited to keep track of small mobile teams using our USB based fingerprint scanner which converts portable devices such as cell phones and tablets into mobile scanning stations.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span> or <Link to="/contact-us">get in touch</Link></PageBreak>

      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
          Our product has a scaled pricing model which allows you to choose the package that best suits your needs and offers maximum value for money. The system is a month-to-month subscription so you are not locked into a fixed term contract and your monthly bill will be reflective of the number of active users on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/biometric.svg" heading="Multiple Biometric Options">
        <p>
          You will receive full support for a wide range of biometric devices. Available options include fingerprint and facial recognition, blood vessel mapping, thermal temperature detection and palm scanning technologies. Additional options for smaller teams include USB based units that can simply be linked directly into a Microsoft Windows PC or Android Device through which people can scan to clock their activity during the day or night.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/multisite.svg" heading="Multisite Control">
        <p>
          Due to the system being cloud-based, multiple sites can be controlled from a single centralised location. The devices link up in real-time allowing for immediate data retrieval. This ensures transparency in the workplace and eliminates the need to wait for managers to manually send time sheets and other documentation to head office. The instant reporting saves time and allows for quick action to be taken if necessary.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="Configurable Shift Rules">
        <p>
          Work rules such as automatic deduction of lunch and tea breaks, removal of duplicated scans and automated rounding of start and end times, can be customized to suit each company uniquely. Using Machine Learning Technology, our system's Shift Sense Function will automatically detect and assign shifts based on employees' start and end scans.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/access.svg" heading="Access Control">
        <p>
          Movement within specific areas or locations can be tightly controlled and restricted using our biometric system in conjunction with turnstiles, magnetic door locks and vehicle gates. Facial and fingerprint recognition reliably and securely denies access to unauthorised people. Access control can also be an invaluable tool in ensuring people don’t abuse toilet and restroom breaks.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/portable.svg" heading="Portable Biometric Options">
        <p>
          Our portable biometric offerings are perfect for keeping track of people when they leave the premises for any off-site activities. Real time data can be fed back to administrative users to quickly ascertain where groups of people are with live geolocation features.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Employee Leave Management">
        <p>
          Beyond Attendance includes a leave management system for tracking and maintaining leave records. The system allows you to create and modify leave codes and types depending on your requirements. In addition to the basics, Beyond Attendance also allows for online document storage for items such as sick notes and letters of warning. These leave records can then be exported into your payroll system when exporting your payroll files.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="24 Hour Work Compatibility">
        <p>
          Beyond Attendance supports both day and night shift configurations and can accommodate work shifts that run continually. Combined with our USB fingerprint scanner, teams can run autonomously while data is sent back to a single centralised administration point in real time.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Overtime Management">
        <p>
          Beyond Attendance includes an overtime management system designed to digitize and simplify the overtime approval process. Overtime can be managed directly by an admin staff member or decentralised to allow production managers to control the overtime for their teams and divisions with admin staff members taking on a global oversite role. The function has a built in audit to ensure overtime issues can be identified and traced accordingly.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default IndexPage
